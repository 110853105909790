import React from "react";
import Youtube from 'react-youtube';
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const VideoBlock = () => {
  const { t } = useTranslation(); 
  const opts = {
    height: '486',
    width: '',
    playerVars: {
      autoplay: 0,
    },
  };
  const handleOnReady = (es) => {
    es.target.pauseVideo();
  }
  return (
    <Row className="d-flex flex-column align-items-center videoblock">
      <Row>
        <Col>
          <h1 className="header">{t(`Info.1`)}</h1>
        </Col>
      </Row>
      <Row className="Colls">
          <Youtube
          className="youtub"
            videoId="6JU99dal19g"
            opts={opts}
            onReady={(e) => handleOnReady(e)}
          />
      </Row>
    </Row>

  );
};

export default VideoBlock;
