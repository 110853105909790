import React from "react";
import "./styles.scss";
import Dashboard from "../../images/InfoBlock/background.png";
import Dashboard2 from "../../images/InfoBlock/table2768.png";
import LeftBackground from "../../images/InfoBlock/left_alling.png";
import Upper from "../../images/InfoBlock/upper768.png";
import RightBackground from "../../images/InfoBlock/right_align.png";
import Downer from "../../images/InfoBlock/downfor768.png";
import Phone1 from "../../images/InfoBlock/Phone1.png";
import Phone2 from "../../images/InfoBlock/Phone2.png";
import Slider from "react-slick";
import { Row, Col, Image, Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const InfoBlocks = () => {
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1

        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true

        }
      }
    ]
  };  
  return (
    <Row className="infoblock">
      <Row>
        <Col lg={5} xl={5} md={12} className="d-flex flex-column justify-content-center align-item-center textleft">
          <div className="header">{t("Info.14")}</div>
          <div>
            <div className="list">
              <div className="str">
                {t("Info.15")}
              </div>
              <div className="str">
                {t("Info.16")}
              </div>
              <div className="str">
                {t("Info.17")}
              </div>
            </div>
            <Image src={LeftBackground} className="image_left" alt="Background left" />
            <Image src={Upper} className="image_upper" alt="Background upper" />
          </div>
        </Col>
        <Col lg={5} xl={5} md={12} className="table768">
          <Image className="image_system" src={Dashboard} />
          <Image className="image_system768" src={Dashboard2} />
          <Image src={RightBackground} className="image_right" alt="Background right" />
          <Image src={Downer} className="image_down" alt="Background down" />
        </Col>
      </Row>
      <Row className="w-sm-100">
        <Col lg={5} xl={5} md={12} className="imagetable768">
          <Slider {...settings}>
            <div>
              <Image className="image_phone" src={Phone1} alt="Phone" />
            </div>
            <div>
              <Image className="image_phone" src={Phone2} alt="Phone" />
            </div>
            <div>
              <Image className="image_phone" src={Phone1} alt="Phone" />
            </div>
          </Slider>
        </Col>  
        <Col lg={5} xl={5} md={12} className="d-flex flex-column align-item-center block textright">
          <div className="header phone">{t("Info.18")}</div>
          <div>
            <div className="list">
              <div className="str">
                {t("Info.19")}
              </div>
              <div className="str phone">
                {t("Info.20")}
              </div>
              <div className="str phone">
                {t("Info.21")}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Row>

  );
};

export default InfoBlocks;
