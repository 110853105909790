import React from "react";
import "./styles.scss";
import Dashboard from "../../images/InfoBlock/dashboard.png";
import LeftBackground from "../../images/InfoBlock/left_background.png";
import LeftBackground576 from "../../images/InfoBlock/topleft.png";
import RightBackground from "../../images/InfoBlock/rightbackground.png";
import Phone from "../../images/InfoBlock/Phone.png";
import Phone768 from "../../images/InfoBlock/left768.png";
import uptable768 from "../../images/InfoBlock/up.png";
import uptable576 from "../../images/InfoBlock/Backgroundup.png";
import downtable768 from "../../images/InfoBlock/down.png";
import { Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const SedSystem = () => {
  const { t } = useTranslation();
  return (  
    
    <Row className="d-flex flex-column align-items-xs-center sedsystem">
      <Row className="sedheader">
        <h2>{t("Info.3")}</h2>
      </Row>
      <Row>
        <Col lg={5} xl={6} sm={12} className="d-flex flex-column justify-content-center align-item-center textleft">
          <div className="header">{t("Info.4")}</div>
          <div>
            <div className="list">
              <div className="str">
                {t("Info.5")}
              </div>
              <div className="str">
                {t("Info.6")}
              </div>
              <div className="str">
                {t("Info.7")}
              </div>
            </div>
            <Image src={LeftBackground} className="image_left" alt="Background left" />
            <Image src={LeftBackground576} className="image_left576" alt="Background left" />
          </div>
        </Col>
        
        <Col xl={6} md={12} sm={12}>
          <Image className="image_uptable" src={uptable768} alt="Upper " />
          <Image className="image_upback" src={uptable576} alt="Upper " />
          <Image className="image_system" src={Dashboard} />
          <Image className="image_downtable" src={downtable768} alt="Down table" />
          <Image className="image_system768" src={Phone768} alt="Phone" />
          <Image src={RightBackground} className="image_right" alt="Background right" />
        </Col>
      </Row>
      <Row className="w-sm-100">
        <Col xl={6} md={12} sm={12} className="tablet768">
          <Image className="image_phone" src={Phone} alt="Phone" />
        </Col>
        <Col lg={5} xl={6} sm={12} className="textRigth d-flex flex-column justify-content-lg-center align-item-center">
          <div className="header phone">{t("Info.11")}</div>
          <div>
            <div className="list phone">
              <div className="str">
                {t("Info.8")}
              </div>
              <div className="str phone">
                {t("Info.9")}
              </div>
              <div className="str phone">
                {t("Info.10")}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Row>

  );
};

export default SedSystem;
