import React from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import AccessApplication from "../AccessApplication";
import "./styles.scss";

const ModalForm = (props) => {
  const { t } = useTranslation();
  const { title, show, handleClose } = props
  return (
    <>
      <Modal className="callback" show={show} onHide={handleClose}>
        <Modal.Body >
          <AccessApplication
            title={t(title)}
            subtitle=" "
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalForm;
