import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from '../components/Common/Layout'
import SEO from '../components/Common/SEO';
import VideoBlock from '../components/VideoBlock';
import MobileBusiness from '../components/MobileBusiness';
import SedSystem from '../components/SedSystem';
import Fact from '../components/Fact';
import InfoBlocks from '../components/InfoBlocks';
import InfoForAss from '../components/InfoForAss';
import Modal from '../components/ModalForm';

const IndexPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [sites, setSites] = useState("");

  const handleOpen = (tit, site) => {
    setTitle(tit);
    setSites(site);
    setIsOpen(true);
  }
  const handleClose = () => {
    setIsOpen(false);
  }
  return (
  <Layout path="info">
    <SEO />
    <Modal show={isOpen} title={title} site={sites} handleClose={() => handleClose()}  />
    <VideoBlock />
    <MobileBusiness
      forWho="info"
      handleOpen={(e, t) => handleOpen(e, t)}    
    />
    <SedSystem />
    <Fact 
      handleOpen={(e, t) => handleOpen(e, t)}    
      />
    <InfoBlocks />
    <InfoForAss 
      handleOpen={(e, t) => handleOpen(e, t)}    
      />
  </Layout>
);
}
export default IndexPage;
